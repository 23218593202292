import { useState, useEffect } from 'react';
import { removeBackground } from "@imgly/background-removal";
import { ImageUploader } from './components/ImageUploader';
import { AnimationPreview, TextConfig } from './components/AnimationPreview';
import { Download, Play } from 'lucide-react';
import { fonts } from './config/fonts';
import { Select } from './components/ui/Select';
import { defaultContent } from './config/defaults';

function App() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [processedImage, setProcessedImage] = useState<string | null>(defaultContent.subjectImage);
  const [originalImage, setOriginalImage] = useState<string | null>(defaultContent.backgroundImage);
  const [animationUrl, setAnimationUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [textConfig, setTextConfig] = useState<TextConfig>(defaultContent.textConfig);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleImageSelect = async (file: File) => {
    try {
      setIsProcessing(true);
      setError(null);
      setProcessedImage(null);
      setAnimationUrl(null);

      // Validate file size
      if (file.size > 5 * 1024 * 1024) {
        throw new Error('Image size should be less than 5MB');
      }

      // Validate file type
      if (!file.type.startsWith('image/')) {
        throw new Error('Please upload a valid image file');
      }
      const originalImageUrl = URL.createObjectURL(file);
      setOriginalImage(originalImageUrl);

      // Process image with background removal
      const blob = await removeBackground(file, {
        debug: true,
        device: "cpu",
        proxyToWorker: true,
        model: "isnet_fp16",
      });

      const url = URL.createObjectURL(blob);
      setProcessedImage(url);
    } catch (err) {
      console.error('Error processing image:', err);
      setError(
        err instanceof Error 
          ? err.message 
          : 'Failed to process image. Please try again with a different image.'
      );
      setProcessedImage(null);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAnimationComplete = (blob: Blob) => {
    setIsAnimating(false);
    // Clean up previous URL if it exists
    if (animationUrl) {
      URL.revokeObjectURL(animationUrl);
    }
    const url = URL.createObjectURL(blob);
    setAnimationUrl(url);
  };

  const handleDownloadImage = () => {
    const canvas = document.querySelector('canvas');
    if (canvas) {
      const link = document.createElement('a');
      link.download = 'processed-image.png';
      link.href = canvas.toDataURL();
      link.click();
    }
  };

  // Clean up URL when component unmounts
  useEffect(() => {
    return () => {
      if (animationUrl) {
        URL.revokeObjectURL(animationUrl);
      }
    };
  }, []);

  useEffect(() => {
    // Load fonts and ensure they're ready before rendering
    const fontPromises = fonts.map(font => {
      const link = document.createElement('link');
      link.href = font.url;
      link.rel = 'stylesheet';
      document.head.appendChild(link);
      return document.fonts.ready;
    });

    Promise.all(fontPromises).then(() => {
      // Force a re-render after fonts are loaded
      setTextConfig(prev => ({ ...prev }));
    });
  }, []);

  const selectedFont = fonts.find(f => f.family === textConfig.font) || fonts[0];

  const handleFontChange = (fontFamily: string) => {
    const newFont = fonts.find(f => f.family === fontFamily) || fonts[0];
    setTextConfig({
      ...textConfig,
      font: fontFamily,
      fontWeight: newFont.weights?.bold ? textConfig.fontWeight : 'normal',
      fontStyle: newFont.weights?.italic ? textConfig.fontStyle : 'normal'
    });
  };

  const updateTextConfig = (updates: Partial<TextConfig>) => {
    setTextConfig(prev => ({ ...prev, ...updates }));
    // Clear animation URL when text config changes
    if (animationUrl) {
      URL.revokeObjectURL(animationUrl);
      setAnimationUrl(null);
    }
  };

  const handleTextPositionChange = (x: number, y: number) => {
    updateTextConfig({ x, y });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100">
      <div className="min-h-screen flex flex-col">
        <div className="text-center py-4 px-4">
          <h1 className="text-4xl md:text-3xl sm:text-2xl font-bold text-gray-900 mb-2">
            Animate Any Image
          </h1>
          <p className="text-lg sm:text-base text-gray-600">
            Inspired by <a href="https://textbehindimage.rexanwong.xyz/" target="_blank" className="text-blue-600 font-semibold">Text Behind Image</a>.
          </p>
          <p className="text-sm text-gray-600">
            Made with ❤️ by <a href="https://vipro.studio" target="_blank" className="text-blue-600 font-semibold">Vipro Studio Team</a>
          </p>
        </div>

        {error && (
          <div className="max-w-md mx-auto mb-2 p-4 mx-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
            {error}
          </div>
        )}

        <div className="flex-1 flex flex-col lg:flex-row gap-6 p-4 md:p-6 min-h-0">
          {/* Left Panel - Preview */}
          <div className="flex-1 bg-white rounded-xl shadow-lg p-4 md:p-6 flex flex-col">
            <h2 className="text-xl font-semibold mb-4">Preview</h2>
            <div className="flex-1 flex flex-col min-h-0">
              {processedImage ? (
                <div className="flex-1">
                  <div className="h-full">
                    <AnimationPreview
                      originalImage={originalImage}
                      processedImage={processedImage}
                      isAnimating={isAnimating}
                      textConfig={textConfig}
                      onAnimationComplete={handleAnimationComplete}
                      onTextPositionChange={handleTextPositionChange}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex-1 flex items-center justify-center border-2 border-dashed border-gray-200 rounded-lg">
                  <p className="text-gray-500">Preview will appear here</p>
                </div>
              )}
            </div>
          </div>

          {/* Right Panel - Upload */}
          <div className="w-full lg:w-[400px] bg-white rounded-xl shadow-lg p-4 md:p-6 flex flex-col lg:max-h-[calc(100vh)]">
            <h2 className="text-xl font-semibold mb-6">Upload & Configure</h2>
            <div className="lg:overflow-y-auto flex-1">
              <ImageUploader
                onImageSelect={handleImageSelect}
                isProcessing={isProcessing}
              />
              
              {processedImage && (
                <>
                  <div className="mt-6 space-y-4">
                    <input
                      type="text"
                      placeholder="Enter text"
                      className="w-full p-2 border rounded"
                      value={textConfig.text}
                      onChange={(e) => updateTextConfig({ text: e.target.value })}
                    />
                    
                    <div className="flex gap-2">
                      <Select
                        value={textConfig.font}
                        onValueChange={(value) => handleFontChange(value)}
                        options={fonts.map(font => ({
                          value: font.family,
                          label: font.name,
                          style: { fontFamily: font.family }
                        }))}
                        className="w-full"
                        style={{ fontFamily: textConfig.font }}
                      />

                      <Select
                        value={textConfig.fontWeight}
                        onValueChange={(value) => updateTextConfig({ fontWeight: value })}
                        options={[
                          { value: 'normal', label: 'Regular' },
                          ...(selectedFont.weights?.bold ? [{ value: 'bold', label: 'Bold' }] : [])
                        ]}
                        className="w-full"
                      />
                    </div>

                    <div className="space-y-2">
                      <label className="text-sm text-gray-600">Text Color</label>
                      <div className="flex items-center gap-2">
                        <input
                          type="color"
                          value={textConfig.color}
                          onChange={(e) => updateTextConfig({ color: e.target.value })}
                          className="w-10 h-10 p-1 rounded cursor-pointer"
                        />
                        <input
                          type="text"
                          value={textConfig.color}
                          onChange={(e) => updateTextConfig({ color: e.target.value })}
                          className="flex-1 p-2 border rounded"
                          pattern="^#[0-9A-Fa-f]{6}$"
                          placeholder="#ffffff"
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label className="text-sm text-gray-600">Shadow Color</label>
                      <div className="flex items-center gap-2">
                        <input
                          type="color"
                          value={textConfig.shadowColor}
                          onChange={(e) => updateTextConfig({ shadowColor: e.target.value })}
                          className="w-10 h-10 p-1 rounded cursor-pointer"
                        />
                        <input
                          type="text"
                          value={textConfig.shadowColor}
                          onChange={(e) => updateTextConfig({ shadowColor: e.target.value })}
                          className="flex-1 p-2 border rounded"
                          pattern="^#[0-9A-Fa-f]{6}$"
                          placeholder="#000000"
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <div className="flex items-center gap-4">
                        <input
                          type="range"
                          min="10"
                          max="300"
                          className="flex-1"
                          value={textConfig.fontSize}
                          onChange={(e) => updateTextConfig({ fontSize: Number(e.target.value) })}
                        />
                        <input
                          type="number"
                          min="10"
                          max="300"
                          className="w-20 p-2 border rounded"
                          value={textConfig.fontSize}
                          onChange={(e) => updateTextConfig({ fontSize: Number(e.target.value) })}
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label className="text-sm text-gray-600">X Position (%)</label>
                      <div className="flex items-center gap-4">
                        <input
                          type="range"
                          min="0"
                          max="100"
                          className="flex-1"
                          value={textConfig.x}
                          onChange={(e) => updateTextConfig({ x: Number(e.target.value) })}
                        />
                        <input
                          type="number"
                          min="0"
                          max="100"
                          className="w-20 p-2 border rounded"
                          value={textConfig.x}
                          onChange={(e) => updateTextConfig({ x: Number(e.target.value) })}
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <label className="text-sm text-gray-600">Y Position (%)</label>
                      <div className="flex items-center gap-4">
                        <input
                          type="range"
                          min="0"
                          max="100"
                          className="flex-1"
                          value={textConfig.y}
                          onChange={(e) => updateTextConfig({ y: Number(e.target.value) })}
                        />
                        <input
                          type="number"
                          min="0"
                          max="100"
                          className="w-20 p-2 border rounded"
                          value={textConfig.y}
                          onChange={(e) => updateTextConfig({ y: Number(e.target.value) })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 space-y-2">
                    <button
                      onClick={handleDownloadImage}
                      className="flex items-center justify-center gap-2 w-full px-6 py-3 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors"
                    >
                      <Download className="w-5 h-5" />
                      Download Static Image
                    </button>
                  </div>
                  
                  <div className="flex flex-col lg:flex-row mt-2 space-y-2 md:space-x-2 lg:space-x-2 lg:space-y-0">
                    <button
                      onClick={() => setIsAnimating(true)}
                      disabled={isAnimating}
                      className="flex items-center justify-center gap-2 w-full px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:bg-blue-300"
                    >
                      <Play className="w-5 h-5" />
                      {animationUrl ? 'Replay' : 'Start Animation'}
                    </button>

                    {animationUrl && (
                      <a
                        href={animationUrl}
                        download="animation.webm"
                        className="flex items-center justify-center gap-2 w-full px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                      >
                        <Download className="w-5 h-5" />
                        Download
                      </a>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;