import { useRef, useEffect, useState } from 'react';

export interface TextConfig {
  text: string;
  font: string;
  fontSize: number;
  fontWeight: string;
  x: number;
  y: number;
  color: string;
  shadowColor: string;
  fontStyle: string;
  textDecoration: string;
}

interface AnimationPreviewProps {
  originalImage: string | null;
  processedImage: string | null;
  isAnimating: boolean;
  textConfig: TextConfig;
  onAnimationComplete: (blob: Blob) => void;
  onTextPositionChange?: (x: number, y: number) => void;
}

interface LetterPosition {
  char: string;
  x: number;
  y: number;
  targetY: number;
  delay: number;
}

export function AnimationPreview({ 
  originalImage, 
  processedImage, 
  isAnimating, 
  textConfig, 
  onAnimationComplete,
  onTextPositionChange 
}: AnimationPreviewProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const animationFrameRef = useRef<number>();
  const startTimeRef = useRef<number>(0);
  const backgroundImageRef = useRef<HTMLImageElement | null>(null);
  const subjectImageRef = useRef<HTMLImageElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const lettersRef = useRef<LetterPosition[]>([]);
  const [isFontLoaded, setIsFontLoaded] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartPos = useRef({ x: 0, y: 0 });
  const initialTextPos = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (isAnimating && canvasRef.current) {
      // Setup MediaRecorder
      const stream = canvasRef.current.captureStream(30); // 30 FPS
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'video/webm;codecs=vp9',
        videoBitsPerSecond: 5000000 // 5Mbps
      });

      const chunks: Blob[] = [];
      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        onAnimationComplete(blob);
      };

      // Start recording
      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();

      // Start animation
      startTimeRef.current = 0;
      animationFrameRef.current = requestAnimationFrame(animate);
    }
  }, [isAnimating]);

  // Initialize letter positions when text changes or animation starts
  useEffect(() => {
    if (canvasRef.current && textConfig.text) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d')!;
      
      // Setup text properties for measurement
      ctx.font = `${textConfig.fontWeight} ${textConfig.fontSize}px ${textConfig.font}`;
      ctx.textAlign = 'center';
      
      // Calculate total width of text
      const text = textConfig.text;
      const totalWidth = ctx.measureText(text).width;
      const startX = (canvas.width * textConfig.x) / 100;
      const targetY = (canvas.height * textConfig.y) / 100;
      
      // Calculate starting X position to center the text
      const startingX = startX - (totalWidth / 2);
      
      // Initialize each letter's position
      let currentX = 0;
      lettersRef.current = text.split('').map((char, index) => {
        const letterWidth = ctx.measureText(char).width;
        // Center each letter around its position
        const x = startingX + currentX + (letterWidth / 2);
        currentX += letterWidth;
        
        return {
          char,
          x: x,
          y: -canvas.height, // Start 20% above the canvas
          targetY: targetY,
          delay: index * 100 // Stagger the fall of each letter
        };
      });
    }
  }, [textConfig, isAnimating]);

  // Add font loading check
  useEffect(() => {
    if (textConfig.font) {
      document.fonts.ready.then(() => {
        const font = new FontFace(textConfig.font, `local(${textConfig.font})`);
        font.load().then(() => {
          setIsFontLoaded(true);
          if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d')!;
            renderFrame(ctx, 1);
          }
        }).catch(() => {
          // Fallback if font loading fails
          setIsFontLoaded(true);
          if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d')!;
            renderFrame(ctx, 1);
          }
        });
      });
    }
  }, [textConfig.font]);

  const renderFrame = (ctx: CanvasRenderingContext2D, progress: number) => {
    ctx.fillStyle = '#000';
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    
    // Draw background with blur
    if (backgroundImageRef.current) {
      const blurAmount = 60 * (1 - progress);
      ctx.filter = `blur(${blurAmount}px)`;
      ctx.drawImage(backgroundImageRef.current, 0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.filter = 'none';
    }

    // Calculate animation durations based on text length
    const subjectDuration = 2000; // 2 seconds for subject animation
    const textStartDelay = 2000; // Start text after subject animation
    const baseLetterDelay = 50; // Delay between each letter
    const textDuration = (lettersRef.current.length * baseLetterDelay) + 500; // Add 500ms buffer
    const totalDuration = subjectDuration + textStartDelay + textDuration;

    const currentTime = progress * totalDuration;
    const subjectProgress = Math.min(1, currentTime / subjectDuration);
    const textProgress = Math.max(0, Math.min(1, (currentTime - textStartDelay) / textDuration));

    // Draw animated text after subject animation starts to complete
    if (textConfig.text && isAnimating) {
      ctx.save();
      ctx.font = `${textConfig.fontStyle} ${textConfig.fontWeight} ${textConfig.fontSize}px "${textConfig.font}"`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      
      ctx.shadowColor = textConfig.shadowColor;
      ctx.shadowBlur = 4;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;
      ctx.fillStyle = textConfig.color;

      // Animate each letter
      lettersRef.current.forEach((letter, index) => {
        const letterDelay = index * baseLetterDelay;
        const letterProgress = Math.max(0, Math.min(1, 
          (textProgress * textDuration - letterDelay) / 500));
        
        const easeOutBack = (x: number): number => {
          const c1 = 1.70158;
          const c3 = c1 + 1;
          return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
        };

        const currentY = letter.y + (letter.targetY - letter.y) * easeOutBack(letterProgress);
        
        ctx.fillText(letter.char, letter.x, currentY);
      });
      
      ctx.restore();
    } else if (textConfig.text) {
      // Draw static text when not animating
      ctx.save();
      ctx.font = `${textConfig.fontWeight} ${textConfig.fontSize}px ${textConfig.font}`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.shadowColor = textConfig.shadowColor;
      ctx.shadowBlur = 4;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;
      ctx.fillStyle = textConfig.color;
      
      const x = (ctx.canvas.width * textConfig.x) / 100;
      const y = (ctx.canvas.height * textConfig.y) / 100;
      ctx.fillText(textConfig.text, x, y);
      
      ctx.restore();
    }

    // Draw subject image
    if (subjectImageRef.current) {
      const scale = isAnimating ? (2 - subjectProgress) : 1;
      const baseScale = Math.min(
        ctx.canvas.width / subjectImageRef.current.width,
        ctx.canvas.height / subjectImageRef.current.height
      );
      const finalScale = baseScale * scale;
      
      const imageWidth = subjectImageRef.current.width * finalScale;
      const imageHeight = subjectImageRef.current.height * finalScale;
      const imageX = (ctx.canvas.width - imageWidth) / 2;
      const imageY = (ctx.canvas.height - imageHeight) / 2;
      
      ctx.drawImage(subjectImageRef.current, imageX, imageY, imageWidth, imageHeight);
    }
  };

  const animate = (timestamp: number) => {
    if (!startTimeRef.current) startTimeRef.current = timestamp;
    const totalDuration = 4000 + (lettersRef.current.length * 100); // Base duration + time for letters
    const progress = Math.min((timestamp - startTimeRef.current) / totalDuration, 1);

    const ctx = canvasRef.current!.getContext('2d')!;
    renderFrame(ctx, progress);

    if (progress < 1 && isAnimating) {
      animationFrameRef.current = requestAnimationFrame(animate);
    } else if (mediaRecorderRef.current?.state === 'recording') {
      mediaRecorderRef.current.stop();
    }
  };

  useEffect(() => {
    if (!processedImage || !canvasRef.current || !containerRef.current) return;

    // Load both background and subject images
    backgroundImageRef.current = new Image();
    subjectImageRef.current = new Image();
    
    backgroundImageRef.current!.src = originalImage!;
    subjectImageRef.current!.src = processedImage!;
    
    const loadImages = Promise.all([
      new Promise<void>(resolve => {
        if (backgroundImageRef.current) {
          backgroundImageRef.current.onload = () => resolve();
        }
      }),
      new Promise<void>(resolve => {
        if (subjectImageRef.current) {
          subjectImageRef.current.onload = () => resolve();
        }
      })
    ]);

    loadImages.then(() => {
      const canvas = canvasRef.current!;
      const container = containerRef.current!;
      const ctx = canvas.getContext('2d')!;
      
      // Set canvas size to match original image dimensions
      canvas.width = backgroundImageRef.current!.width;
      canvas.height = backgroundImageRef.current!.height;

      // Scale the canvas element for display while maintaining aspect ratio
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;
      const scale = Math.min(
        containerWidth / canvas.width,
        containerHeight / canvas.height
      );
      
      canvas.style.width = `${canvas.width * scale}px`;
      canvas.style.height = `${canvas.height * scale}px`;

      renderFrame(ctx, 1);
    });

    // Update resize handler
    const handleResize = () => {
      if (!containerRef.current || !canvasRef.current || !backgroundImageRef.current) return;
      
      const container = containerRef.current;
      const canvas = canvasRef.current;
      
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;
      const scale = Math.min(
        containerWidth / canvas.width,
        containerHeight / canvas.height
      );
      
      canvas.style.width = `${canvas.width * scale}px`;
      canvas.style.height = `${canvas.height * scale}px`;
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (mediaRecorderRef.current?.state === 'recording') {
        mediaRecorderRef.current.stop();
      }
    };
  }, [processedImage, textConfig]);

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (isAnimating) return;
    
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;
    
    // Calculate text bounds
    const ctx = canvas.getContext('2d')!;
    ctx.font = `${textConfig.fontWeight} ${textConfig.fontSize}px ${textConfig.font}`;
    const metrics = ctx.measureText(textConfig.text);
    
    const textX = (canvas.width * textConfig.x) / 100;
    const textY = (canvas.height * textConfig.y) / 100;
    
    // Calculate text bounds with padding
    const padding = 20; // Pixels of padding around text for easier selection
    const textBounds = {
      left: textX - (metrics.width / 2) - padding,
      right: textX + (metrics.width / 2) + padding,
      top: textY - (textConfig.fontSize / 2) - padding,
      bottom: textY + (textConfig.fontSize / 2) + padding
    };
    
    // Check if click is within text bounds
    if (x >= textBounds.left && 
        x <= textBounds.right && 
        y >= textBounds.top && 
        y <= textBounds.bottom) {
      setIsDragging(true);
      dragStartPos.current = { x: e.clientX, y: e.clientY };
      initialTextPos.current = { x: textConfig.x, y: textConfig.y };
      canvas.style.cursor = 'grabbing';
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDragging || !canvasRef.current || isAnimating) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    
    const deltaX = e.clientX - dragStartPos.current.x;
    const deltaY = e.clientY - dragStartPos.current.y;
    
    const scaleX = 100 / rect.width;
    const scaleY = 100 / rect.height;
    
    const newX = Math.max(0, Math.min(100, initialTextPos.current.x + (deltaX * scaleX)));
    const newY = Math.max(0, Math.min(100, initialTextPos.current.y + (deltaY * scaleY)));
    
    onTextPositionChange?.(newX, newY);
  };

  const handleMouseUp = () => {
    if (isDragging && canvasRef.current) {
      setIsDragging(false);
      canvasRef.current.style.cursor = 'grab';
    }
  };

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.style.cursor = 'grab';
    }
  }, [processedImage]);

  // Add event listeners for mouse up outside canvas
  useEffect(() => {
    const handleGlobalMouseUp = () => {
      handleMouseUp();
    };

    window.addEventListener('mouseup', handleGlobalMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [isDragging]);

  return (
    <div 
      ref={containerRef}
      className="relative w-full h-full flex items-center justify-center bg-black rounded-lg"
      style={{ aspectRatio: '16/9' }}
    >
      <canvas
        ref={canvasRef}
        className="max-w-full max-h-full object-contain"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      />
    </div>
  );
}
