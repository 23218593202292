export interface FontConfig {
  name: string;
  family: string;
  url: string;
  weights: {
    normal?: string;
    bold?: string;
    italic?: string;
  };
}

export const fonts: FontConfig[] = [
  {
    name: 'Roboto',
    family: 'Roboto',
    url: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap',
    weights: {
      normal: '400',
      bold: '700',
      italic: '400'
    }
  },
  {
    name: 'Playfair Display',
    family: 'Playfair Display',
    url: 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400&display=swap',
    weights: {
      normal: '400',
      bold: '700',
      italic: '400'
    }
  },
  {
    name: 'Montserrat',
    family: 'Montserrat',
    url: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap',
    weights: {
      normal: '400',
      bold: '700',
      italic: '400'
    }
  },
  {
    name: 'Oswald',
    family: 'Oswald',
    url: 'https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap',
    weights: {
      normal: '400',
      bold: '700'
    }
  },
  {
    name: 'Lora',
    family: 'Lora',
    url: 'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400&display=swap',
    weights: {
      normal: '400',
      bold: '700',
      italic: '400'
    }
  },
  {
    name: 'Bebas Neue',
    family: 'Bebas Neue',
    url: 'https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap',
    weights: {
      normal: '400'
    }
  },
  {
    name: 'Dancing Script',
    family: 'Dancing Script',
    url: 'https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap',
    weights: {
      normal: '400',
      bold: '700'
    }
  },
  {
    name: 'Abril Fatface',
    family: 'Abril Fatface',
    url: 'https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap',
    weights: {
      normal: '400'
    }
  },
  {
    name: 'Permanent Marker',
    family: 'Permanent Marker',
    url: 'https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap',
    weights: {
      normal: '400'
    }
  },
  {
    name: 'Pacifico',
    family: 'Pacifico',
    url: 'https://fonts.googleapis.com/css2?family=Pacifico&display=swap',
    weights: {
      normal: '400'
    }
  },
  {
    name: 'Quicksand',
    family: 'Quicksand',
    url: 'https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap',
    weights: {
      normal: '400',
      bold: '700'
    }
  },
  {
    name: 'Shadows Into Light',
    family: 'Shadows Into Light',
    url: 'https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap',
    weights: {
      normal: '400'
    }
  },
  {
    name: 'Righteous',
    family: 'Righteous',
    url: 'https://fonts.googleapis.com/css2?family=Righteous&display=swap',
    weights: {
      normal: '400'
    }
  }
]
